import React, { createContext, useState, useEffect, useContext } from 'react';
const ThemeContext = createContext();
export const useTheme = () => useContext(ThemeContext);
export const ThemeProvider = ({ children }) => {

    const [theme, setTheme] = useState(() => {
        const storedTheme = sessionStorage.getItem('theme');
        return storedTheme ? storedTheme : 'themeColor';
    });

    const [language, setLanguage] = useState(() => {
        const storedLanguage = sessionStorage.getItem('language');
        return storedLanguage ? storedLanguage : 'English';
    });

    useEffect(() => {
        document.body.className = `theme-${theme}`;
        sessionStorage.setItem('theme', theme);
    }, [theme]);

    useEffect(() => { sessionStorage.setItem('language', language) }, [language]);

    const toggleTheme = () => setTheme(p => (p === 'themeColor' ? 'sassTheam' : 'themeColor'))
    const toggleLanguage = () => setLanguage(p => (p === 'Arabic' ? 'English' : 'Arabic'))

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme,toggleLanguage,language }}>
            {children}
        </ThemeContext.Provider>
    );
};