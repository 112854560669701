import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import './App.css';
import "./Bootstrap/css/bootstrap.min.css"
import 'react-toastify/dist/ReactToastify.css';

const RoutingConfig = lazy(() => import("./Components/RoutingConfig"))
const Auth = lazy(() => import("./Components/Auth/Auth"))
const HealthbergPrivateRoute = lazy(() => import("./PrivateRoute/HealthbergPrivateRoute"))

const App = () => {
  return (
    <Suspense fallback={() => <div>Loading ....</div>}>
      <ToastContainer />
      <Router >
        <Routes>
          <Route path='*' element={<Auth />} />
          <Route pathname="/auth" element={<Auth />} />
          <Route path="v1/*" element={<HealthbergPrivateRoute><RoutingConfig /></HealthbergPrivateRoute>} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
